export const HatsElectionsEligibilityAbi = [
  {
    inputs: [{ internalType: 'string', name: '_version', type: 'string' }],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    inputs: [{ internalType: 'uint128', name: 'termEnd', type: 'uint128' }],
    name: 'ElectionClosed',
    type: 'error',
  },
  { inputs: [], name: 'InvalidTermEnd', type: 'error' },
  { inputs: [], name: 'NextTermNotReady', type: 'error' },
  { inputs: [], name: 'NotAdmin', type: 'error' },
  { inputs: [], name: 'NotBallotBox', type: 'error' },
  { inputs: [], name: 'NotElected', type: 'error' },
  { inputs: [], name: 'TermEnded', type: 'error' },
  { inputs: [], name: 'TermNotEnded', type: 'error' },
  { inputs: [], name: 'TooManyWinners', type: 'error' },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint128',
        name: 'termEnd',
        type: 'uint128',
      },
      {
        indexed: false,
        internalType: 'address[]',
        name: 'winners',
        type: 'address[]',
      },
    ],
    name: 'ElectionCompleted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint128',
        name: 'nextTermEnd',
        type: 'uint128',
      },
    ],
    name: 'ElectionOpened',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint8',
        name: 'version',
        type: 'uint8',
      },
    ],
    name: 'Initialized',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint128',
        name: 'termEnd',
        type: 'uint128',
      },
    ],
    name: 'NewTermStarted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint128',
        name: 'termEnd',
        type: 'uint128',
      },
      {
        indexed: false,
        internalType: 'address[]',
        name: 'accounts',
        type: 'address[]',
      },
    ],
    name: 'Recalled',
    type: 'event',
  },
  {
    inputs: [],
    name: 'ADMIN_HAT',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'pure',
    type: 'function',
  },
  {
    inputs: [],
    name: 'BALLOT_BOX_HAT',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'pure',
    type: 'function',
  },
  {
    inputs: [],
    name: 'HATS',
    outputs: [{ internalType: 'contract IHats', name: '', type: 'address' }],
    stateMutability: 'pure',
    type: 'function',
  },
  {
    inputs: [],
    name: 'IMPLEMENTATION',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'pure',
    type: 'function',
  },
  {
    inputs: [],
    name: 'currentTermEnd',
    outputs: [{ internalType: 'uint128', name: '', type: 'uint128' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint128', name: '_termEnd', type: 'uint128' },
      { internalType: 'address[]', name: '_winners', type: 'address[]' },
    ],
    name: 'elect',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint128', name: 'termEnd', type: 'uint128' },
      { internalType: 'address', name: 'candidates', type: 'address' },
    ],
    name: 'electionResults',
    outputs: [{ internalType: 'bool', name: 'elected', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint128', name: 'termEnd', type: 'uint128' }],
    name: 'electionStatus',
    outputs: [{ internalType: 'bool', name: 'isElectionOpen', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_wearer', type: 'address' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    name: 'getWearerStatus',
    outputs: [
      { internalType: 'bool', name: 'eligible', type: 'bool' },
      { internalType: 'bool', name: 'standing', type: 'bool' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'hatId',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'pure',
    type: 'function',
  },
  {
    inputs: [],
    name: 'nextTermEnd',
    outputs: [{ internalType: 'uint128', name: '', type: 'uint128' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'uint128', name: '_termEnd', type: 'uint128' },
      {
        internalType: 'address[]',
        name: '_recallees',
        type: 'address[]',
      },
    ],
    name: 'recall',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'uint128', name: '_newTermEnd', type: 'uint128' }],
    name: 'setNextTerm',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes', name: '_initData', type: 'bytes' }],
    name: 'setUp',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'startNextTerm',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'version',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'version_',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
] as const;
