import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react';

export const Lock: ComponentWithAs<'svg', IconProps> = createIcon({
  displayName: 'Lock',
  viewBox: '0 0 24 24',
  path: (
    <path
      d="M6.37493 21.6868C5.85933 21.6868 5.41809 21.5034 5.05123 21.1365C4.68374 20.769 4.5 20.3275 4.5 19.8119V10.4372C4.5 9.9216 4.68374 9.48005 5.05123 9.11257C5.41809 8.7457 5.85933 8.56227 6.37493 8.56227H7.3124V6.68734C7.3124 5.39051 7.76957 4.28492 8.68392 3.37058C9.59764 2.45686 10.7029 2 11.9997 2C13.2966 2 14.4022 2.45686 15.3165 3.37058C16.2302 4.28492 16.6871 5.39051 16.6871 6.68734V8.56227H17.6245C18.1402 8.56227 18.5817 8.7457 18.9492 9.11257C19.316 9.48005 19.4995 9.9216 19.4995 10.4372V19.8119C19.4995 20.3275 19.316 20.769 18.9492 21.1365C18.5817 21.5034 18.1402 21.6868 17.6245 21.6868H6.37493ZM6.37493 19.8119H17.6245V10.4372H6.37493V19.8119ZM11.9997 16.9995C12.5153 16.9995 12.9569 16.816 13.3244 16.4492C13.6912 16.0817 13.8747 15.6402 13.8747 15.1245C13.8747 14.6089 13.6912 14.1674 13.3244 13.7999C12.9569 13.433 12.5153 13.2496 11.9997 13.2496C11.4841 13.2496 11.0429 13.433 10.676 13.7999C10.3085 14.1674 10.1248 14.6089 10.1248 15.1245C10.1248 15.6402 10.3085 16.0817 10.676 16.4492C11.0429 16.816 11.4841 16.9995 11.9997 16.9995ZM9.18734 8.56227H14.8121V6.68734C14.8121 5.90611 14.5387 5.24207 13.9919 4.69522C13.445 4.14836 12.781 3.87493 11.9997 3.87493C11.2185 3.87493 10.5545 4.14836 10.0076 4.69522C9.46077 5.24207 9.18734 5.90611 9.18734 6.68734V8.56227Z"
      fill="currentColor"
    />
  ),
});
