import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react';

export const DecentHourGlass: ComponentWithAs<'svg', IconProps> = createIcon({
  displayName: 'DecentHourGlass',
  viewBox: '0 0 46 68',
  path: (
    <svg
      width="46"
      height="68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.0596 61.7188H2.93945V64.5778H43.0596V61.7188Z"
        fill="#3E3743"
      />
      <path
        d="M43.0596 3.82947H2.93945V6.68851H43.0596V3.82947Z"
        fill="#3E3743"
      />
      <path
        d="M43.0596 14.1848V3.82947H2.93945V14.1832C2.93945 22.605 8.12979 29.8144 15.485 32.7876C16.9732 33.3883 17.9463 34.8336 17.9463 36.4375C17.9463 38.0413 16.9732 39.4851 15.485 40.0874C8.12979 43.0605 2.93945 50.2699 2.93945 58.6918V64.9519H43.0596V58.6918C43.0596 50.2699 37.8692 43.0605 30.514 40.0874C29.0259 39.4867 28.0528 38.0413 28.0528 36.4375C28.0528 34.8336 29.0259 33.3898 30.514 32.7876C37.8692 29.8144 43.0596 22.605 43.0596 14.1832V14.1848Z"
        stroke="#57515C"
      />
      <path
        d="M1.99458 0.615234H44.0054C45.0546 0.615234 45.9072 1.46788 45.9072 2.51704V3.76747C45.9072 4.11772 45.6235 4.40141 45.2733 4.40141H0.726708C0.376459 4.40141 0.0927734 4.11772 0.0927734 3.76747V2.51704C0.0927734 1.46788 0.945415 0.615234 1.99458 0.615234Z"
        fill="#5F376C"
      />
      <path
        d="M44.0054 68H1.99458C0.945415 68 0.0927734 67.1474 0.0927734 66.0982V64.8478C0.0927734 64.4975 0.376461 64.2138 0.726707 64.2138H45.2733C45.6235 64.2138 45.9072 64.4975 45.9072 64.8478V66.0982C45.9072 67.1474 45.0546 68 44.0054 68Z"
        fill="#5F376C"
      />
      <path
        d="M36.8466 10.7092C36.8466 19.131 32.68 25.5148 28.1886 29.3136C24.7653 32.2091 19.8508 30.7067 17.8174 29.3136C16.1692 28.1836 13.4686 25.4403 11.7031 22.1422C20.4229 20.139 25.5815 15.0406 36.845 10.7076L36.8466 10.7092Z"
        fill="#D3C2EA"
      />
      <path
        d="M23 19.3244V54.7011"
        stroke="#D3C2EA"
        strokeWidth="0.950902"
      />
      <path
        d="M8.7793 61.7108H38.7137L34.609 58.6838L31.3902 56.3113C26.4518 52.6709 19.675 52.8357 14.919 56.7091L8.7793 61.7108Z"
        fill="#D3C2EA"
      />
      <path
        opacity="0.2"
        d="M35.1048 4.40143H39.75V15.7552C39.75 22.2182 35.7673 27.7508 30.1221 30.033C30.1221 30.033 35.8322 24.4908 35.6706 16.2306C35.5089 7.97048 35.1048 4.40143 35.1048 4.40143Z"
        fill="#F8F4FC"
      />
      <path
        opacity="0.2"
        d="M35.6948 56.4683H39.725C39.2464 50.5521 35.4237 45.5805 30.1494 43.4473C30.1494 43.4473 35.5014 48.6424 35.6948 56.4683Z"
        fill="#F8F4FC"
      />
    </svg>
  ),
});
