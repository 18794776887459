import { ComponentWithAs, createIcon, IconProps } from '@chakra-ui/react';

export const Vote: ComponentWithAs<'svg', IconProps> = createIcon({
  displayName: 'Vote',
  viewBox: '0 0 16 16',
  path: (
    <path
      d="M12 8.70042H11.5467L10.2133 10.1003H11.4867L12.6667 11.5002H3.33333L4.52 10.1003H5.88667L4.55333 8.70042H4L2 10.8003V13.6001C2 13.9714 2.14048 14.3274 2.39052 14.59C2.64057 14.8525 2.97971 15 3.33333 15H12.6667C13.0203 15 13.3594 14.8525 13.6095 14.59C13.8595 14.3274 14 13.9714 14 13.6001V10.8003L12 8.70042ZM11.3333 5.16566L8.03333 8.63043L5.66667 6.15259L8.97333 2.68783L11.3333 5.16566ZM8.50667 1.20393L4.26 5.66263C4.1982 5.72738 4.14917 5.8043 4.11571 5.88897C4.08226 5.97365 4.06504 6.06442 4.06504 6.15609C4.06504 6.24777 4.08226 6.33854 4.11571 6.42321C4.14917 6.50789 4.1982 6.58481 4.26 6.64956L7.56 10.1003C7.82 10.3873 8.24 10.3873 8.5 10.1003L12.74 5.66263C12.8018 5.59787 12.8508 5.52095 12.8843 5.43628C12.9177 5.3516 12.935 5.26083 12.935 5.16916C12.935 5.07749 12.9177 4.98672 12.8843 4.90204C12.8508 4.81737 12.8018 4.74045 12.74 4.67569L9.44 1.21093C9.37985 1.14485 9.30768 1.09213 9.22776 1.05591C9.14783 1.01968 9.06179 1.00067 8.97474 1.00002C8.88769 0.999365 8.8014 1.01708 8.721 1.0521C8.64059 1.08713 8.56771 1.13876 8.50667 1.20393Z"
      fill="currentColor"
    />
  ),
});
