export function StetoscopeIllustrationDesktop() {
  return (
    <svg
      width="527"
      height="329"
      viewBox="0 0 527 329"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M338.335 97.1032C337.446 108.257 323.268 119.268 300.778 122.516C267.309 127.343 227.156 114.335 229.485 90.9809C231.796 67.831 273.541 59.6501 305.462 67.9997C327.01 73.643 339.215 85.9941 338.335 97.0943V97.1032Z"
        fill="#501464"
      />
      <path
        d="M335.997 93.1811C335.108 104.335 321.499 115.39 299.951 118.718C267.896 123.66 229.502 110.794 231.814 87.4402C234.098 64.2903 274.101 55.9674 304.627 64.2105C325.232 69.7739 336.877 82.0808 335.997 93.1899V93.1811Z"
        fill="#F8F4FC"
      />
      <path
        d="M285.959 120.217C267.051 120.217 248.597 114.672 238.81 105.435C233.218 100.146 230.711 94.0772 231.369 87.3957C232.036 80.6877 235.743 74.9646 242.392 70.3861C256.553 60.6257 282.777 57.8574 304.742 63.7846C324.361 69.0818 337.393 81.1935 336.442 93.2254C335.482 105.337 320.503 116.003 300.022 119.161C295.39 119.871 290.661 120.217 285.959 120.217ZM280.572 61.5574C265.922 61.5574 251.913 64.8937 242.899 71.1049C236.472 75.5325 232.898 81.0427 232.258 87.4757C231.627 93.8731 234.036 99.6939 239.423 104.778C251.45 116.145 276.883 121.823 299.889 118.274C319.961 115.177 334.628 104.849 335.553 93.1455C336.477 81.5395 323.712 69.8182 304.511 64.6275C296.822 62.5512 288.599 61.5485 280.572 61.5485V61.5574Z"
        fill="#501464"
      />
      <path
        d="M309.426 58.603H258.33L264.864 80.3332L266.322 86.1007C268.686 91.8682 279.105 95.0536 288.643 93.7758C295.719 92.8264 298.955 89.2951 300.43 86.1806L309.409 58.603H309.426Z"
        fill="#F8F4FC"
      />
      <path
        d="M284.297 94.5035C275.701 94.5035 267.931 91.185 265.913 86.2693L264.438 80.4398L257.735 58.1594H310.031L300.858 86.3137C299.4 89.3927 296.119 93.2169 288.706 94.2107C287.23 94.4059 285.746 94.5035 284.297 94.5035ZM258.926 59.0467L265.291 80.2091L266.758 85.9942C268.918 91.247 278.919 94.6366 288.59 93.3322C295.613 92.3917 298.688 88.816 300.04 85.9855L308.814 59.0467H258.935H258.926Z"
        fill="#501464"
      />
      <path
        d="M137.48 187.405L76.1606 123.599C61.8843 108.736 55.2615 88.1683 57.9994 67.1657C60.9863 44.2554 74.9605 23.6433 96.3572 10.6265L100.757 17.8315C81.2808 29.686 69.0758 47.5917 66.3912 68.2482C63.991 86.7042 69.7779 104.743 82.2764 117.751L143.595 181.558L137.488 187.405H137.48Z"
        fill="#F8F4FC"
      />
      <path
        d="M137.462 188.035L75.8404 123.909C61.4663 108.949 54.7992 88.2481 57.5549 67.1124C60.5595 44.0691 74.6137 23.3415 96.1172 10.2537L96.4993 10.0229L101.362 17.991L100.98 18.2217C81.6186 30.0051 69.4935 47.7956 66.8178 68.3191C64.4354 86.642 70.178 104.548 82.5787 117.458L144.2 181.584L137.453 188.044L137.462 188.035ZM96.197 11.2384C75.1381 24.202 61.3773 44.5836 58.435 67.2277C55.7148 88.0972 62.293 108.532 76.4804 123.297L137.488 186.784L142.955 181.557L81.9475 118.071C69.3602 104.974 63.5287 86.7929 65.9467 68.2038C68.6401 47.5384 80.7741 29.6235 100.144 17.6981L96.2059 11.2473L96.197 11.2384Z"
        fill="#501464"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M135.336 185.062L74.0171 121.256C60.843 107.547 54.8161 88.0175 57.4651 67.6626C60.5231 44.2111 74.506 23.3151 95.8316 10.3337L97.2184 12.6051C76.5862 25.1694 63.0654 45.3558 60.1142 68.0088C57.5629 87.5651 63.3411 106.296 75.9462 119.419L137.265 183.226L135.345 185.071L135.336 185.062Z"
          fill="#DCC8F0"
        />
      </g>
      <path
        d="M113.034 1.25599C111.132 0.235589 108.785 0.0227912 106.5 0.972211C103.78 2.11684 101.966 4.86746 101.984 7.81332C102.011 12.6492 106.349 16.0476 110.874 15.1958C114.385 14.5303 116.856 11.5223 116.963 8.09732C116.99 7.40522 116.785 6.73089 116.456 6.12752L114.678 2.90648C114.296 2.21438 113.727 1.63755 113.025 1.26488L113.034 1.25599Z"
        fill="#501464"
      />
      <path
        d="M110.882 15.1961L103.024 18.6298C101.833 19.3663 100.269 19.0203 99.5131 17.8491L95.8595 12.188C95.0683 10.9635 95.4594 9.3219 96.7217 8.58543L103.122 3.90063C103.478 8.90505 105.922 12.7382 110.882 15.1872V15.1961Z"
        fill="#501464"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M110.883 15.1956L103.025 18.6293C101.833 19.3658 100.269 19.0199 99.5134 17.8486L95.8598 12.1875C95.0686 10.963 95.4597 9.32141 96.722 8.58494L103.122 3.90015C103.478 8.90457 105.922 12.7377 110.883 15.1867V15.1956Z"
          fill="#DCC8F0"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M67.467 59.7568C67.3692 59.7568 67.2714 59.7479 67.1736 59.7212C66.4536 59.5615 66.0003 58.8516 66.1603 58.1329C66.4092 57.0238 72.5872 30.6884 99.8421 15.7107C100.491 15.3557 101.3 15.5954 101.656 16.2343C102.011 16.882 101.771 17.6895 101.131 18.0444C74.9606 32.4188 68.8271 58.4523 68.7648 58.7096C68.6226 59.3307 68.0715 59.7479 67.467 59.7479V59.7568Z"
          fill="#DCC8F0"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M506.816 456.889L329.65 292.799C329.588 289.117 328.619 285.15 326.77 281.273L326.415 280.519L263.958 179.41C259.789 168.86 252.935 158.461 243.681 149.303L225.36 131.194C223.022 128.878 219.36 128.54 216.64 130.395C213.057 132.826 212.586 137.919 215.662 140.963L232.667 157.804C242.463 166.225 247.388 180.368 247.388 180.368C252.428 194.166 250.588 207.467 242.108 216.304C241.85 216.57 241.583 216.845 241.308 217.103C241.228 217.174 241.156 217.253 241.085 217.324C224.782 232.524 192.567 227.813 170.557 205.754L150.014 185.16C146.725 181.859 141.551 181.371 137.693 183.988C132.679 187.396 131.995 194.503 136.262 198.806L155.063 217.715C166.797 229.516 180.46 237.786 194.194 242.435L307.409 306.827L308.156 307.129C310.76 308.167 313.303 308.664 315.676 308.655L492.433 472.364L506.816 456.889ZM256.757 233.101C256.757 233.101 256.793 233.065 256.811 233.039C256.829 233.021 256.846 233.003 256.864 232.986C256.908 232.941 256.944 232.897 256.98 232.861C257.451 232.382 257.904 231.885 258.34 231.388C258.615 231.069 258.891 230.749 259.158 230.43C259.38 230.164 259.593 229.898 259.806 229.632C260.269 229.046 260.713 228.443 261.14 227.83C261.184 227.768 261.238 227.697 261.282 227.635C263.105 224.991 264.58 222.178 265.718 219.206C265.834 218.895 265.949 218.593 266.056 218.283C266.216 217.821 266.385 217.36 266.527 216.89C266.714 216.304 266.891 215.71 267.06 215.115C267.105 214.964 267.14 214.813 267.176 214.654C267.247 214.37 267.327 214.086 267.398 213.802L311.107 285.843C311.196 286.18 311.214 286.632 310.903 287.04C310.6 287.448 310.209 287.493 309.907 287.448L236.196 244.831C244.294 242.604 251.308 238.647 256.766 233.11L256.757 233.101Z"
          fill="#DCC8F0"
        />
      </g>
      <path
        d="M213.965 131.522L150.041 70.3155C136.085 56.9526 117.115 48.8249 95.194 46.8285C72.1527 44.7255 46.9513 49.5703 22.3189 60.848L18.7898 53.1726C44.7646 41.2827 71.4503 36.1808 95.9672 38.4168C119.817 40.5996 140.539 49.5171 155.9 64.2198L219.823 125.426L213.965 131.522Z"
        fill="#F8F4FC"
      />
      <path
        d="M213.974 132.152L213.654 131.841L149.73 70.6349C135.845 57.343 116.973 49.2596 95.1493 47.2631C72.1791 45.1691 47.0578 50.0049 22.4964 61.2471L22.0964 61.4334L18.2028 52.9507L18.6119 52.7644C44.6577 40.839 71.4146 35.7193 96.0115 37.9731C119.96 40.1647 140.778 49.1265 156.21 63.9002L220.454 125.408L213.982 132.143L213.974 132.152ZM84.5265 45.9055C88.1356 45.9055 91.7092 46.0653 95.2294 46.3847C117.248 48.3989 136.307 56.5622 150.344 69.9961L213.947 130.892L219.183 125.435L155.579 64.539C140.298 49.9073 119.666 41.0254 95.9139 38.8515C71.6014 36.6332 45.1376 41.6553 19.3673 53.3855L22.5231 60.2535C43.3331 50.7859 64.5432 45.8967 84.5176 45.8967L84.5265 45.9055Z"
        fill="#501464"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M218.187 133.891L154.263 72.6844C122.83 42.5869 71.4318 37.3785 23.3314 59.4015L22.2202 56.9791C71.3252 34.4947 123.879 39.8985 156.112 70.7591L220.036 131.966L218.187 133.882V133.891Z"
          fill="#DCC8F0"
        />
      </g>
      <path
        d="M326.761 276.517L326.406 275.762L263.949 174.654C259.78 164.104 252.926 153.704 243.672 144.547L225.351 126.437C223.013 124.121 219.351 123.784 216.631 125.639C213.048 128.07 212.577 133.163 215.653 136.207L232.658 153.048C242.454 161.468 247.379 175.612 247.379 175.612C252.419 189.41 250.579 202.71 242.099 211.548C241.841 211.814 241.574 212.089 241.299 212.347C241.219 212.418 241.148 212.497 241.077 212.568C224.774 227.768 192.558 223.056 170.548 200.998L150.014 180.404C146.725 177.103 141.551 176.615 137.693 179.232C132.679 182.639 131.995 189.747 136.262 194.05L155.063 212.959C166.797 224.76 180.46 233.03 194.194 237.679L307.409 302.071L308.156 302.373C315.872 305.461 323.054 303.81 326.895 298.211C330.637 292.746 330.548 284.44 326.761 276.517ZM310.894 282.275C310.591 282.684 310.2 282.728 309.898 282.684L236.187 240.066C244.286 237.839 251.299 233.882 256.757 228.345C256.775 228.327 256.793 228.309 256.811 228.283C256.829 228.265 256.846 228.247 256.864 228.229C256.908 228.185 256.944 228.141 256.98 228.105C257.451 227.626 257.904 227.129 258.34 226.632C258.615 226.313 258.891 225.993 259.158 225.674C259.38 225.408 259.593 225.141 259.806 224.875C260.269 224.29 260.713 223.686 261.14 223.074C261.184 223.012 261.238 222.941 261.282 222.879C263.105 220.235 264.58 217.422 265.718 214.449C265.834 214.139 265.949 213.837 266.056 213.527C266.216 213.065 266.385 212.604 266.527 212.134C266.714 211.548 266.891 210.954 267.06 210.359C267.105 210.208 267.14 210.057 267.176 209.898C267.247 209.614 267.327 209.33 267.398 209.046L311.107 281.086C311.196 281.424 311.214 281.876 310.903 282.284L310.894 282.275Z"
        fill="#501464"
      />
      <path
        d="M10.1836 70.3774C15.4417 70.3774 19.7043 66.1228 19.7043 60.8744C19.7043 55.626 15.4417 51.3713 10.1836 51.3713C4.9256 51.3713 0.663147 55.626 0.663147 60.8744C0.663147 66.1228 4.9256 70.3774 10.1836 70.3774Z"
        fill="#501464"
      />
      <path
        d="M6.53053 68.0174C9.45167 68.0174 11.8197 65.6535 11.8197 62.7377C11.8197 59.8219 9.45167 57.4583 6.53053 57.4583C3.6094 57.4583 1.24139 59.8219 1.24139 62.7377C1.24139 65.6535 3.6094 68.0174 6.53053 68.0174Z"
        fill="#501464"
      />
      <path
        d="M14.193 52.2499L22.4068 49.8451C23.6424 49.366 25.038 49.9605 25.5359 51.185L27.9362 57.0857C28.4606 58.3634 27.8027 59.8185 26.496 60.2799L19.2156 63.909C19.8201 59.5879 18.1488 55.7015 14.193 52.2499Z"
        fill="#501464"
      />
      <path
        d="M320.607 277.687L306.222 293.16L496.159 469.09L510.544 453.617L320.607 277.687Z"
        fill="#501464"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M502.291 462.355L321.454 296.987C319.347 298.859 315.872 299.578 310.28 297.528C301.204 294.21 194.656 232.364 194.656 232.364C173.197 231.202 143.355 197.165 133.63 185.346C133.035 188.372 133.87 191.646 136.253 194.05L155.054 212.959C166.788 224.76 180.451 233.03 194.185 237.679L307.4 302.071L308.147 302.373C311.551 303.739 314.858 304.174 317.818 303.757C317.818 303.757 317.827 303.757 317.836 303.757L496.175 468.938L502.291 462.364V462.355Z"
          fill="#DCC8F0"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M232.658 153.057C240.356 159.676 245.05 169.836 246.703 173.873C246.792 174.077 246.872 174.272 246.943 174.459C246.943 174.459 246.001 165.444 241.387 158.354C237.156 151.841 219.422 134.663 213.955 129.206C213.662 130.573 212.986 133.509 215.653 136.207L232.658 153.048V153.057Z"
          fill="#DCC8F0"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M14.1916 52.2494L22.4054 49.8446C23.641 49.3655 25.0366 49.96 25.5344 51.1845L27.9348 57.0852C28.4592 58.3629 27.8013 59.818 26.4946 60.2794L19.2142 63.9086C19.8187 59.5874 18.1474 55.7011 14.1916 52.2494Z"
          fill="#DCC8F0"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M315.143 277.537L261.514 189.277C260.767 214.672 247.869 232.835 217.636 231.433C216.169 231.22 236.188 240.066 236.188 240.066C244.286 237.839 251.3 233.882 256.758 228.345C256.776 228.327 256.794 228.31 256.811 228.283C256.829 228.265 256.847 228.247 256.864 228.23C256.909 228.185 256.945 228.141 256.98 228.105C257.451 227.626 257.905 227.129 258.34 226.632C258.616 226.313 258.891 225.994 259.158 225.674C259.38 225.408 259.594 225.142 259.807 224.876C260.269 224.29 260.714 223.687 261.14 223.075C261.185 223.012 261.238 222.941 261.283 222.879C263.105 220.235 264.581 217.422 265.718 214.45C265.834 214.139 265.949 213.838 266.056 213.527C266.216 213.066 266.385 212.604 266.527 212.134C266.714 211.548 266.892 210.954 267.061 210.359C267.105 210.208 267.141 210.058 267.176 209.898C267.23 209.676 267.292 209.463 267.345 209.241C267.363 209.179 267.381 209.108 267.398 209.046L311.107 281.087C311.196 281.424 311.214 281.876 310.903 282.285C310.601 282.693 310.21 282.737 309.908 282.693C318.495 285.275 315.152 277.546 315.152 277.546L315.143 277.537Z"
          fill="#DCC8F0"
        />
      </g>
      <path
        d="M39.9635 48.0262C39.4035 48.0262 38.8791 47.6712 38.7013 47.1122C38.4702 46.4113 38.8524 45.6658 39.5457 45.4351C40.2213 45.2133 56.2313 39.9605 64.1517 39.7742H64.1871C64.9071 39.7742 65.5027 40.3509 65.5205 41.0785C65.5383 41.815 64.9516 42.4183 64.2138 42.4361C56.6933 42.6047 40.5504 47.9108 40.3815 47.9551C40.2393 47.9995 40.0969 48.0262 39.9635 48.0262Z"
        fill="#DCC8F0"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M292.013 93.057L311.027 117.254C311.027 117.254 328.273 115.382 330.62 107.422L300.929 84.7073C300.929 84.7073 300.298 91.309 292.022 93.0481L292.013 93.057Z"
          fill="#DCC8F0"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M292.013 93.0574L291.373 76.3315L302.724 77.6003L298.422 89.7033C298.422 89.7033 293.488 93.3147 292.013 93.0574Z"
          fill="#DCC8F0"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M301.392 83.27C302.752 79.0996 305.294 71.3003 307.152 65.5771C308.201 62.3562 309.037 59.7919 309.321 58.9223C309.392 58.7094 309.428 58.6028 309.428 58.6028H258.332L259.994 64.1129C265.416 67.2274 272.919 69.206 280.768 69.8005V93.8555L278.893 120.528C278.893 120.528 322.646 121.779 327.962 108.514L301.392 83.27Z"
          fill="#DCC8F0"
        />
      </g>
      <path
        d="M293.719 71.6554C289.888 76.0565 290.359 82.7202 294.768 86.5445L422.971 197.245L436.838 181.309L308.636 70.6085C304.227 66.7842 297.551 67.2544 293.719 71.6554Z"
        fill="#501464"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M293.951 66.6067C299.747 65.8258 304.601 64.2109 308.281 62.1168C308.974 59.9784 309.428 58.5942 309.428 58.5942H258.332L258.945 60.6262C267.301 65.9234 281.417 68.2837 293.943 66.5978L293.951 66.6067Z"
          fill="#DCC8F0"
        />
      </g>
      <path
        d="M285.826 65.6481C271.647 65.6481 257.886 61.5488 250.899 54.8052C247.086 51.1229 245.317 46.7307 245.779 42.0989C246.241 37.5027 248.855 33.4212 253.335 30.289C263.229 23.368 282.208 21.3359 298.475 25.453C313.276 29.2063 322.735 37.7246 322.023 46.6598C321.312 55.5861 310.547 62.9596 295.231 65.0182C292.12 65.4352 288.964 65.6392 285.826 65.6392V65.6481ZM280.999 29.5525C271.381 29.5525 262.313 31.5933 256.9 35.3821C253.904 37.4762 252.251 39.9429 251.966 42.7202C251.691 45.4265 252.784 47.9907 255.22 50.342C262.376 57.2453 279.212 60.91 294.395 58.8692C304.396 57.5293 315.303 52.6225 315.81 46.1717C316.192 41.3448 309.836 34.7431 296.929 31.469C291.799 30.1646 286.306 29.5436 280.99 29.5436L280.999 29.5525Z"
        fill="#501464"
      />
      <path
        d="M316.138 46.2693C315.64 52.5781 307.258 58.7803 293.941 60.5638C274.127 63.2257 250.312 55.7812 251.646 42.578C252.961 29.4813 277.674 24.9472 296.599 29.7387C309.373 32.9774 316.636 39.9872 316.129 46.2693H316.138Z"
        fill="#F8F4FC"
      />
      <path
        d="M285.825 61.5399C273.398 61.5399 261.121 57.9818 255.192 52.2498C252.188 49.3483 250.845 46.0828 251.201 42.5247C251.556 38.9666 253.557 35.8878 257.13 33.3856C265.717 27.3785 282.358 25.6571 296.706 29.295C308.955 32.4006 317.125 39.3836 316.573 46.2869C316.022 53.2345 306.742 59.2772 293.994 60.9897C291.31 61.3535 288.554 61.5221 285.816 61.5221L285.825 61.5399ZM281.131 28.2925C271.815 28.2925 263.068 30.3243 257.646 34.1219C254.286 36.4733 252.419 39.3305 252.09 42.6224C251.761 45.8877 253.014 48.9133 255.814 51.6196C262.882 58.443 279.247 62.0988 293.888 60.129C306.208 58.4697 315.178 52.7644 315.702 46.2338C316.218 39.7654 308.324 33.1637 296.501 30.1646C291.505 28.9046 286.243 28.2925 281.14 28.2925H281.131Z"
        fill="#501464"
      />
      <path
        d="M291.719 31.7264C285.986 38.399 289.799 42.6493 311.018 48.2571C289.79 42.6404 281.221 43.9358 272.047 54.6101C281.23 43.9269 278.047 39.5348 263.122 35.5863C278.047 39.5348 285.986 38.3901 291.719 31.7175V31.7264Z"
        fill="#CAFF8A"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M429.593 189.623L301.39 78.9219C298.51 76.4286 294.67 75.7721 291.283 76.828C290.696 80.3329 291.879 84.0418 294.768 86.544L422.97 197.245L429.602 189.623H429.593Z"
          fill="#DCC8F0"
        />
      </g>
      <path
        d="M440.617 165.311L442.377 164.495C444.706 163.412 447.471 163.864 449.311 165.657C450.333 166.651 451.426 167.857 452.333 169.179C454.28 172.036 453.48 175.932 450.582 177.831L449.986 178.221L440.617 165.311Z"
        fill="#501464"
      />
      <path
        d="M387.2 183.944L388.382 168.354C388.622 165.142 389.591 162.01 391.28 159.259C391.316 159.197 391.351 159.144 391.387 159.082C394.231 154.512 398.836 151.318 404.081 150.075L415.335 147.414L393.885 186.668L387.2 183.935V183.944Z"
        fill="#F8F4FC"
      />
      <path
        d="M394.09 187.236L386.738 184.228L387.947 168.318C388.196 164.991 389.227 161.779 390.907 159.028L391.023 158.842C393.877 154.272 398.597 150.918 403.993 149.649L416.215 146.766L394.099 187.236H394.09ZM387.672 183.651L393.69 186.109L414.473 148.079L404.188 150.51C399.024 151.735 394.49 154.938 391.77 159.312L391.663 159.481C390.045 162.116 389.067 165.186 388.827 168.38L387.672 183.642V183.651Z"
        fill="#501464"
      />
      <path
        d="M479.758 210.99L426.901 149.623C425.239 146.793 420.776 146.704 420.776 146.704C404.509 147.352 395.842 159.375 389.859 173.19C383.877 187.006 390.01 192.959 390.01 192.959L468.379 242.835L479.766 210.998L479.758 210.99Z"
        fill="#F8F4FC"
      />
      <path
        d="M468.601 243.5L389.69 193.27C389.433 193.021 383.405 186.952 389.441 173.013C399.513 149.756 412.136 146.606 420.75 146.26C420.972 146.26 425.506 146.385 427.275 149.401L480.255 210.901L468.592 243.509L468.601 243.5ZM390.286 192.604L468.139 242.152L479.251 211.078L426.563 149.907C424.999 147.263 420.803 147.139 420.768 147.139C403.495 147.831 395.397 161.513 390.268 173.35C384.685 186.251 389.859 192.134 390.295 192.595L390.286 192.604Z"
        fill="#501464"
      />
      <path
        d="M486.415 226.695C485.766 235.284 480.254 242.852 473.552 243.651C466.156 244.538 460.209 236.987 460.991 226.712C461.773 216.437 468.858 208.878 476.121 209.756C482.699 210.546 487.064 218.105 486.415 226.695Z"
        fill="#F8F4FC"
      />
      <path
        d="M472.405 244.166C469.783 244.166 467.267 243.136 465.241 241.193C461.863 237.963 460.111 232.542 460.556 226.677C461 220.767 463.605 215.319 467.525 212.116C470.165 209.96 473.232 208.957 476.183 209.321C482.85 210.129 487.544 217.777 486.868 226.739C486.193 235.665 480.37 243.296 473.623 244.104C473.223 244.148 472.814 244.174 472.414 244.174L472.405 244.166ZM474.957 210.129C472.583 210.129 470.227 211.043 468.076 212.799C464.343 215.861 461.863 221.069 461.436 226.748C461.009 232.347 462.663 237.502 465.854 240.554C468.032 242.631 470.681 243.554 473.508 243.216C479.855 242.453 485.331 235.186 485.98 226.668C486.62 218.176 482.273 210.945 476.077 210.2C475.703 210.155 475.33 210.129 474.965 210.129H474.957Z"
        fill="#501464"
      />
      <path
        d="M388.117 178.491L387.599 179.213L460.856 231.621L461.374 230.9L388.117 178.491Z"
        fill="#501464"
      />
      <path
        d="M468.218 220.679C464.387 225.08 464.858 231.743 469.267 235.568L597.47 346.268L611.337 330.332L483.134 219.632C478.725 215.807 472.049 216.278 468.218 220.679Z"
        fill="#501464"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M604.1 338.646L475.898 227.946C473.018 225.453 469.951 222.87 467.844 221.149C463.595 226.854 466.262 233.199 469.275 235.568L597.478 346.268L604.109 338.646H604.1Z"
          fill="#DCC8F0"
        />
      </g>
      <path
        d="M417.983 197.794C417.681 200.758 415.219 202.488 412.437 202.16C409.654 201.841 406.756 199.48 405.796 196.366C404.596 192.435 406.81 188.815 411.068 189.321C415.317 189.827 418.366 194.059 417.975 197.794H417.983Z"
        fill="#F8F4FC"
      />
      <path
        d="M413.103 202.648C412.863 202.648 412.623 202.63 412.383 202.604C409.29 202.249 406.347 199.676 405.369 196.499C404.694 194.29 405.032 192.08 406.276 190.599C407.405 189.259 409.121 188.647 411.121 188.886C415.77 189.436 418.81 194.068 418.419 197.848C418.268 199.33 417.583 200.625 416.499 201.495C415.557 202.249 414.365 202.648 413.094 202.648H413.103ZM410.303 189.72C408.925 189.72 407.752 190.226 406.961 191.166C405.912 192.417 405.636 194.316 406.223 196.242C407.085 199.054 409.779 201.415 412.49 201.725C413.788 201.876 415.015 201.548 415.948 200.803C416.855 200.075 417.406 199.019 417.539 197.75C417.886 194.387 415.166 190.253 411.014 189.765C410.765 189.738 410.534 189.72 410.294 189.72H410.303Z"
        fill="#501464"
      />
      <path
        d="M416.642 197.165C416.322 200.279 413.736 202.098 410.811 201.761C407.886 201.424 404.837 198.948 403.833 195.665C402.57 191.531 404.899 187.724 409.38 188.256C413.851 188.789 417.051 193.234 416.642 197.165Z"
        fill="#F8F4FC"
      />
      <path
        d="M411.513 202.24C411.264 202.24 411.015 202.223 410.767 202.196C407.522 201.823 404.437 199.126 403.415 195.79C402.704 193.474 403.06 191.158 404.357 189.605C405.531 188.194 407.335 187.556 409.433 187.804C414.313 188.381 417.496 193.243 417.087 197.2C416.927 198.753 416.216 200.111 415.078 201.025C414.091 201.814 412.847 202.24 411.513 202.24ZM408.571 188.647C407.113 188.647 405.877 189.179 405.042 190.173C403.931 191.495 403.646 193.5 404.26 195.532C405.166 198.496 408.002 200.98 410.864 201.309C412.242 201.477 413.531 201.122 414.518 200.333C415.478 199.561 416.065 198.452 416.198 197.112C416.563 193.563 413.7 189.206 409.318 188.691C409.06 188.665 408.811 188.647 408.562 188.647H408.571Z"
        fill="#501464"
      />
    </svg>
  );
}

export function StetoscopeIllustrationMobile() {
  return (
    <svg
      width="278"
      height="146"
      viewBox="0 0 278 146"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M153.724 44.5723C153.321 49.6292 146.892 54.6218 136.696 56.0942C121.521 58.2827 103.316 52.385 104.372 41.7965C105.42 31.3006 124.347 27.5915 138.82 31.3771C148.589 33.9357 154.123 39.5356 153.724 44.5683V44.5723Z"
        fill="#501464"
      />
      <path
        d="M152.664 42.7943C152.261 47.8511 146.09 52.8638 136.321 54.3724C121.787 56.6132 104.38 50.7799 105.428 40.1914C106.464 29.6955 124.601 25.922 138.441 29.6593C147.783 32.1817 153.063 37.7615 152.664 42.7983V42.7943Z"
        fill="#F8F4FC"
      />
      <path
        d="M129.977 55.0521C121.404 55.0521 113.037 52.5378 108.6 48.3499C106.065 45.9522 104.928 43.2005 105.227 40.1712C105.529 37.1298 107.21 34.535 110.224 32.4592C116.645 28.0339 128.534 26.7788 138.493 29.4661C147.388 31.8678 153.297 37.3591 152.865 42.8143C152.43 48.3056 145.639 53.1412 136.353 54.5734C134.253 54.8952 132.109 55.0521 129.977 55.0521ZM127.535 28.4564C120.893 28.4564 114.541 29.969 110.454 32.7851C107.54 34.7925 105.92 37.2908 105.63 40.2074C105.343 43.108 106.436 45.747 108.878 48.0522C114.331 53.2056 125.862 55.7803 136.293 54.1711C145.393 52.7671 152.043 48.0843 152.462 42.778C152.881 37.516 147.094 32.2017 138.388 29.8483C134.902 28.9069 131.174 28.4523 127.535 28.4523V28.4564Z"
        fill="#501464"
      />
      <path
        d="M140.617 27.1167H117.45L120.413 36.9689L121.074 39.5839C122.146 42.1988 126.869 43.643 131.194 43.0637C134.402 42.6332 135.869 41.0322 136.538 39.6201L140.609 27.1167H140.617Z"
        fill="#F8F4FC"
      />
      <path
        d="M129.223 43.3936C125.326 43.3936 121.803 41.889 120.889 39.6603L120.219 37.0172L117.181 26.9155H140.891L136.732 39.6804C136.071 41.0764 134.584 42.8102 131.222 43.2608C130.553 43.3493 129.88 43.3936 129.223 43.3936ZM117.721 27.3178L120.606 36.9126L121.271 39.5355C122.251 41.9171 126.785 43.4539 131.17 42.8625C134.354 42.4361 135.748 40.8149 136.361 39.5316L140.339 27.3178H117.725H117.721Z"
        fill="#501464"
      />
      <path
        d="M62.6579 85.5143L34.8565 56.5852C28.3838 49.8467 25.3811 40.5214 26.6224 30.9991C27.9766 20.6118 34.3124 11.2664 44.0135 5.36475L46.0085 8.63144C37.178 14.0061 31.6443 22.1244 30.4272 31.4899C29.339 39.8576 31.9627 48.0363 37.6294 53.934L65.4307 82.8632L62.6619 85.5143H62.6579Z"
        fill="#F8F4FC"
      />
      <path
        d="M62.6498 85.7998L34.7114 56.7259C28.1943 49.9431 25.1715 40.5576 26.4209 30.9749C27.7832 20.5272 34.1552 11.1295 43.9046 5.19567L44.0779 5.09106L46.2825 8.70368L46.1092 8.80828C37.3311 14.1508 31.8338 22.2168 30.6206 31.5219C29.5405 39.8294 32.1441 47.9478 37.7664 53.8012L65.7048 82.8751L62.6458 85.8038L62.6498 85.7998ZM43.9409 5.64216C34.3929 11.5197 28.154 20.7605 26.8199 31.0271C25.5866 40.4891 28.5691 49.754 35.0016 56.4482L62.6619 85.2326L65.1406 82.8631L37.4803 54.0787C31.7733 48.1408 29.1294 39.8978 30.2256 31.4697C31.4468 22.1002 36.9483 13.9778 45.7304 8.57089L43.9449 5.64619L43.9409 5.64216Z"
        fill="#501464"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M61.686 84.4519L33.8847 55.5228C27.9117 49.3073 25.1791 40.4527 26.3802 31.224C27.7666 20.5913 34.1063 11.1173 43.7752 5.23169L44.4039 6.26149C35.0495 11.958 28.9193 21.1103 27.5812 31.381C26.4245 40.2476 29.0443 48.74 34.7593 54.69L62.5607 83.6191L61.6901 84.4559L61.686 84.4519Z"
          fill="#DCC8F0"
        />
      </g>
      <path
        d="M51.5745 1.1162C50.712 0.653554 49.6479 0.557074 48.6121 0.987531C47.3788 1.50649 46.5566 2.7536 46.5647 4.08922C46.5768 6.28174 48.5436 7.82256 50.595 7.43636C52.187 7.13463 53.3075 5.77085 53.3559 4.21798C53.368 3.90419 53.2752 3.59846 53.1261 3.3249L52.32 1.86451C52.1467 1.55072 51.8888 1.28919 51.5704 1.12022L51.5745 1.1162Z"
        fill="#501464"
      />
      <path
        d="M50.599 7.43641L47.0363 8.99325C46.4962 9.32715 45.7869 9.17031 45.4443 8.63928L43.7878 6.07257C43.4291 5.5174 43.6064 4.77313 44.1787 4.43922L47.0805 2.31519C47.2418 4.58414 48.3501 6.32205 50.599 7.43239V7.43641Z"
        fill="#501464"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M50.5992 7.43617L47.0364 8.993C46.4963 9.32691 45.787 9.17006 45.4444 8.63903L43.7879 6.07233C43.4292 5.51716 43.6065 4.77289 44.1788 4.43898L47.0807 2.31494C47.2419 4.58389 48.3502 6.3218 50.5992 7.43214V7.43617Z"
          fill="#DCC8F0"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M30.9149 27.6399C30.8706 27.6399 30.8262 27.6358 30.7819 27.6238C30.4554 27.5513 30.2499 27.2295 30.3225 26.9036C30.4353 26.4008 33.2364 14.4606 45.5934 7.66981C45.8876 7.50889 46.2544 7.61754 46.4156 7.9072C46.5769 8.20087 46.468 8.56698 46.1778 8.7279C34.3124 15.2451 31.5315 27.0484 31.5033 27.1651C31.4388 27.4467 31.189 27.6358 30.9149 27.6358V27.6399Z"
          fill="#DCC8F0"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M230.111 207.696L149.786 133.299C149.758 131.629 149.319 129.831 148.48 128.073L148.319 127.731L120.002 81.8895C118.112 77.1061 115.004 72.3913 110.809 68.2396L102.502 60.0287C101.442 58.9787 99.7815 58.8258 98.5482 59.6666C96.924 60.7689 96.7104 63.0781 98.1049 64.458L105.815 72.0936C110.256 75.9114 112.489 82.324 112.489 82.324C114.774 88.5797 113.94 94.6101 110.095 98.617C109.978 98.7377 109.857 98.8623 109.732 98.979C109.696 99.0112 109.664 99.0474 109.632 99.0796C102.24 105.971 87.6341 103.835 77.6549 93.8337L68.3407 84.4963C66.8495 82.9998 64.5039 82.7786 62.7547 83.9654C60.4816 85.5102 60.1712 88.7326 62.1058 90.6837L70.63 99.2567C75.9501 104.607 82.1447 108.357 88.3716 110.465L139.702 139.659L140.041 139.796C141.222 140.267 142.374 140.492 143.45 140.488L223.59 214.712L230.111 207.696ZM116.737 106.232C116.737 106.232 116.753 106.216 116.761 106.204C116.77 106.196 116.778 106.188 116.786 106.18C116.806 106.16 116.822 106.14 116.838 106.124C117.052 105.907 117.257 105.681 117.455 105.456C117.58 105.311 117.705 105.166 117.825 105.021C117.926 104.901 118.023 104.78 118.12 104.659C118.329 104.394 118.531 104.12 118.724 103.843C118.744 103.815 118.769 103.782 118.789 103.754C119.615 102.555 120.284 101.28 120.8 99.9325C120.852 99.7917 120.905 99.6549 120.953 99.5141C121.026 99.3049 121.102 99.0957 121.167 98.8825C121.251 98.6169 121.332 98.3474 121.408 98.0779C121.429 98.0095 121.445 97.9411 121.461 97.8687C121.493 97.7399 121.529 97.6112 121.562 97.4825L141.379 130.145C141.419 130.298 141.427 130.503 141.286 130.688C141.149 130.873 140.972 130.893 140.835 130.873L107.415 111.551C111.087 110.541 114.267 108.747 116.741 106.236L116.737 106.232Z"
          fill="#DCC8F0"
        />
      </g>
      <path
        d="M97.3357 60.1777L68.3533 32.4273C62.0257 26.3687 53.4249 22.6837 43.4861 21.7785C33.0394 20.8251 21.6133 23.0216 10.4452 28.1348L8.84519 24.6549C20.6219 19.2641 32.7209 16.951 43.8367 17.9648C54.6501 18.9544 64.0449 22.9975 71.0094 29.6636L99.9916 57.414L97.3357 60.1777Z"
        fill="#F8F4FC"
      />
      <path
        d="M97.3395 60.4632L97.1945 60.3225L68.2122 32.5721C61.9168 26.5457 53.3603 22.8807 43.4658 21.9755C33.0514 21.0261 21.6616 23.2186 10.5257 28.3157L10.3444 28.4002L8.57903 24.5542L8.76451 24.4697C20.5735 19.0629 32.7047 16.7417 43.8567 17.7635C54.7145 18.7572 64.1536 22.8204 71.1503 29.5186L100.278 57.4058L97.3436 60.4592L97.3395 60.4632ZM38.6496 21.36C40.2859 21.36 41.9061 21.4324 43.5021 21.5773C53.4853 22.4905 62.1264 26.1916 68.4903 32.2824L97.3275 59.892L99.7014 57.4179L70.8641 29.8082C63.936 23.1744 54.5816 19.1474 43.8125 18.1618C32.7894 17.156 20.791 19.433 9.10703 24.7513L10.5378 27.8652C19.9729 23.5727 29.5893 21.356 38.6455 21.356L38.6496 21.36Z"
        fill="#501464"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M99.2497 61.2516L70.2674 33.5011C56.0161 19.8552 32.7125 17.4937 10.9043 27.4787L10.4005 26.3805C32.6642 16.1863 56.4917 18.6363 71.1057 32.6281L100.088 60.3785L99.2497 61.2476V61.2516Z"
          fill="#DCC8F0"
        />
      </g>
      <path
        d="M148.476 125.917L148.315 125.575L119.998 79.7332C118.108 74.9499 115 70.235 110.805 66.0833L102.498 57.8724C101.438 56.8224 99.7776 56.6696 98.5443 57.5104C96.9201 58.6127 96.7064 60.9218 98.1009 62.3017L105.811 69.9373C110.252 73.7551 112.485 80.1677 112.485 80.1677C114.77 86.4234 113.936 92.4538 110.091 96.4607C109.974 96.5814 109.853 96.7061 109.729 96.8228C109.692 96.855 109.66 96.8912 109.628 96.9234C102.236 103.815 87.6301 101.678 77.6509 91.6774L68.3407 82.3401C66.8495 80.8436 64.5039 80.6223 62.7547 81.8091C60.4816 83.3539 60.1712 86.5763 62.1058 88.5274L70.6301 97.1003C75.9501 102.451 82.1447 106.2 88.3716 108.308L139.702 137.503L140.041 137.64C143.539 139.04 146.796 138.292 148.537 135.753C150.234 133.275 150.193 129.509 148.476 125.917ZM141.282 128.528C141.145 128.713 140.968 128.733 140.831 128.713L107.411 109.391C111.083 108.381 114.263 106.587 116.737 104.076C116.745 104.068 116.753 104.06 116.761 104.048C116.77 104.04 116.778 104.032 116.786 104.024C116.806 104.004 116.822 103.984 116.838 103.968C117.052 103.75 117.257 103.525 117.455 103.3C117.58 103.155 117.705 103.01 117.825 102.865C117.926 102.745 118.023 102.624 118.12 102.503C118.329 102.238 118.531 101.964 118.724 101.687C118.744 101.658 118.769 101.626 118.789 101.598C119.615 100.399 120.284 99.1239 120.8 97.7762C120.852 97.6354 120.905 97.4987 120.953 97.3579C121.026 97.1487 121.102 96.9395 121.167 96.7262C121.251 96.4607 121.332 96.1912 121.408 95.9216C121.429 95.8533 121.445 95.7849 121.461 95.7124C121.493 95.5837 121.529 95.455 121.562 95.3263L141.379 127.989C141.419 128.142 141.427 128.347 141.286 128.532L141.282 128.528Z"
        fill="#501464"
      />
      <path
        d="M4.94323 32.4551C7.32717 32.4551 9.25982 30.526 9.25982 28.1465C9.25982 25.7669 7.32717 23.8379 4.94323 23.8379C2.55928 23.8379 0.626732 25.7669 0.626732 28.1465C0.626732 30.526 2.55928 32.4551 4.94323 32.4551Z"
        fill="#501464"
      />
      <path
        d="M3.28696 31.385C4.61137 31.385 5.685 30.3133 5.685 28.9913C5.685 27.6693 4.61137 26.5977 3.28696 26.5977C1.96254 26.5977 0.888916 27.6693 0.888916 28.9913C0.888916 30.3133 1.96254 31.385 3.28696 31.385Z"
        fill="#501464"
      />
      <path
        d="M6.76103 24.2362L10.4851 23.1459C11.0453 22.9287 11.6781 23.1982 11.9038 23.7534L12.992 26.4287C13.2298 27.008 12.9315 27.6677 12.3391 27.8769L9.03824 29.5224C9.31231 27.5632 8.55454 25.8012 6.76103 24.2362Z"
        fill="#501464"
      />
      <path
        d="M145.686 126.447L139.164 133.462L225.28 213.227L231.802 206.212L145.686 126.447Z"
        fill="#501464"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M228.06 210.174L146.07 135.197C145.115 136.046 143.539 136.372 141.004 135.443C136.889 133.938 88.581 105.898 88.581 105.898C78.8518 105.371 65.3219 89.9392 60.9127 84.5806C60.6427 85.9524 61.0215 87.4368 62.1016 88.527L70.6259 97.1C75.946 102.451 82.1406 106.2 88.3675 108.308L139.698 137.503L140.037 137.639C141.58 138.259 143.08 138.456 144.422 138.267C144.422 138.267 144.426 138.267 144.43 138.267L225.287 213.159L228.06 210.178V210.174Z"
          fill="#DCC8F0"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M105.811 69.9411C109.301 72.9423 111.429 77.5485 112.179 79.379C112.219 79.4715 112.255 79.5601 112.288 79.6446C112.288 79.6446 111.86 75.5572 109.769 72.3428C107.85 69.39 99.8097 61.6016 97.331 59.1274C97.198 59.747 96.8917 61.0785 98.1008 62.3015L105.811 69.9371V69.9411Z"
          fill="#DCC8F0"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M6.76039 24.2362L10.4844 23.1459C11.0447 22.9287 11.6774 23.1982 11.9031 23.7534L12.9914 26.4287C13.2292 27.008 12.9309 27.6677 12.3384 27.8769L9.0376 29.5224C9.31166 27.5632 8.5539 25.8012 6.76039 24.2362Z"
          fill="#DCC8F0"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M143.209 126.379L118.894 86.363C118.555 97.8768 112.707 106.112 98.9999 105.476C98.3349 105.38 107.411 109.39 107.411 109.39C111.083 108.381 114.263 106.586 116.738 104.076C116.746 104.068 116.754 104.06 116.762 104.048C116.77 104.04 116.778 104.032 116.786 104.024C116.806 104.004 116.822 103.984 116.838 103.968C117.052 103.75 117.257 103.525 117.455 103.3C117.58 103.155 117.705 103.01 117.826 102.865C117.926 102.745 118.023 102.624 118.12 102.503C118.329 102.238 118.531 101.964 118.724 101.687C118.745 101.658 118.769 101.626 118.789 101.598C119.615 100.399 120.284 99.1238 120.8 97.7761C120.853 97.6353 120.905 97.4986 120.953 97.3578C121.026 97.1486 121.102 96.9394 121.167 96.7262C121.251 96.4607 121.332 96.1911 121.409 95.9216C121.429 95.8532 121.445 95.7848 121.461 95.7124C121.485 95.6118 121.513 95.5153 121.538 95.4147C121.546 95.3865 121.554 95.3544 121.562 95.3262L141.379 127.989C141.419 128.142 141.427 128.347 141.286 128.532C141.149 128.717 140.972 128.737 140.835 128.717C144.728 129.888 143.213 126.383 143.213 126.383L143.209 126.379Z"
          fill="#DCC8F0"
        />
      </g>
      <path
        d="M18.4451 22.3215C18.1912 22.3215 17.9534 22.1605 17.8728 21.9071C17.7681 21.5893 17.9413 21.2513 18.2557 21.1467C18.562 21.0461 25.8208 18.6646 29.4118 18.5801H29.4278C29.7543 18.5801 30.0243 18.8416 30.0324 19.1714C30.0405 19.5053 29.7745 19.7789 29.4399 19.787C26.0303 19.8634 18.7112 22.2691 18.6346 22.2892C18.5701 22.3094 18.5056 22.3215 18.4451 22.3215Z"
        fill="#DCC8F0"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M132.722 42.7378L141.343 53.7085C141.343 53.7085 149.162 52.8596 150.226 49.251L136.764 38.9521C136.764 38.9521 136.478 41.9453 132.726 42.7338L132.722 42.7378Z"
          fill="#DCC8F0"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M132.722 42.7381L132.431 35.1548L137.578 35.73L135.628 41.2174C135.628 41.2174 133.391 42.8548 132.722 42.7381Z"
          fill="#DCC8F0"
        />
      </g>
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M136.974 38.3006C137.591 36.4098 138.743 32.8736 139.586 30.2788C140.061 28.8185 140.44 27.6558 140.569 27.2616C140.601 27.165 140.617 27.1167 140.617 27.1167H117.451L118.205 29.6149C120.663 31.027 124.065 31.9241 127.624 32.1936V43.0999L126.773 55.193C126.773 55.193 146.611 55.7602 149.021 49.7459L136.974 38.3006Z"
          fill="#DCC8F0"
        />
      </g>
      <path
        d="M133.495 33.0347C131.758 35.0301 131.972 38.0513 133.971 39.7852L192.097 89.9757L198.384 82.7505L140.258 32.56C138.259 30.8261 135.233 31.0393 133.495 33.0347Z"
        fill="#501464"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M133.601 30.7453C136.228 30.3913 138.429 29.6591 140.098 28.7097C140.412 27.7401 140.617 27.1125 140.617 27.1125H117.451L117.729 28.0338C121.518 30.4355 127.918 31.5056 133.597 30.7413L133.601 30.7453Z"
          fill="#DCC8F0"
        />
      </g>
      <path
        d="M129.917 30.311C123.488 30.311 117.249 28.4524 114.081 25.3949C112.352 23.7254 111.55 21.734 111.76 19.634C111.969 17.5501 113.154 15.6996 115.186 14.2795C119.671 11.1416 128.276 10.2203 135.652 12.0869C142.362 13.7886 146.651 17.6507 146.328 21.7019C146.006 25.749 141.125 29.092 134.181 30.0254C132.77 30.2144 131.339 30.3069 129.917 30.3069V30.311ZM127.728 13.9456C123.367 13.9456 119.256 14.8709 116.802 16.5887C115.444 17.5381 114.694 18.6565 114.565 19.9157C114.44 21.1427 114.936 22.3053 116.04 23.3714C119.285 26.5012 126.918 28.1628 133.802 27.2375C138.336 26.63 143.281 24.4053 143.511 21.4806C143.684 19.2921 140.803 16.299 134.95 14.8145C132.625 14.2231 130.134 13.9415 127.724 13.9415L127.728 13.9456Z"
        fill="#501464"
      />
      <path
        d="M143.66 21.5249C143.434 24.3853 139.634 27.1973 133.596 28.0059C124.612 29.2128 113.815 25.8375 114.42 19.8513C115.016 13.9134 126.221 11.8577 134.801 14.0301C140.593 15.4985 143.886 18.6767 143.656 21.5249H143.66Z"
        fill="#F8F4FC"
      />
      <path
        d="M129.916 28.4485C124.282 28.4485 118.716 26.8353 116.028 24.2365C114.665 22.921 114.057 21.4404 114.218 19.8272C114.379 18.214 115.286 16.8181 116.906 15.6837C120.8 12.9601 128.344 12.1796 134.849 13.829C140.403 15.2371 144.107 18.4031 143.857 21.533C143.607 24.6829 139.4 27.4226 133.62 28.199C132.403 28.364 131.154 28.4405 129.912 28.4405L129.916 28.4485ZM127.788 13.3745C123.564 13.3745 119.599 14.2957 117.14 16.0175C115.617 17.0836 114.77 18.379 114.621 19.8715C114.472 21.352 115.04 22.7238 116.31 23.9508C119.514 27.0444 126.934 28.7019 133.572 27.8088C139.158 27.0565 143.224 24.4698 143.462 21.5089C143.696 18.5762 140.117 15.583 134.757 14.2233C132.492 13.652 130.106 13.3745 127.792 13.3745H127.788Z"
        fill="#501464"
      />
      <path
        d="M132.589 14.931C129.989 17.9563 131.718 19.8833 141.338 22.4259C131.714 19.8793 127.829 20.4666 123.669 25.3063C127.833 20.4626 126.39 18.4713 119.623 16.6811C126.39 18.4713 129.989 17.9523 132.589 14.927V14.931Z"
        fill="#CAFF8A"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M195.099 86.5198L136.973 36.3293C135.667 35.1988 133.926 34.9012 132.391 35.3799C132.125 36.969 132.661 38.6506 133.971 39.7851L192.096 89.9756L195.103 86.5198H195.099Z"
          fill="#DCC8F0"
        />
      </g>
      <path
        d="M200.097 75.497L200.895 75.1269C201.951 74.6361 203.205 74.8412 204.039 75.6538C204.502 76.1044 204.998 76.6515 205.409 77.2509C206.292 78.5463 205.929 80.3124 204.615 81.1733L204.345 81.3504L200.097 75.497Z"
        fill="#501464"
      />
      <path
        d="M175.879 83.9451L176.415 76.8767C176.524 75.4204 176.963 74.0003 177.729 72.7532C177.745 72.725 177.761 72.7009 177.777 72.6727C179.067 70.6009 181.154 69.1527 183.532 68.5895L188.635 67.3826L178.909 85.1802L175.879 83.9411V83.9451Z"
        fill="#F8F4FC"
      />
      <path
        d="M179.002 85.4376L175.669 84.0738L176.218 76.8606C176.33 75.352 176.798 73.8957 177.56 72.6485L177.612 72.5641C178.906 70.4922 181.046 68.9716 183.492 68.3963L189.034 67.0889L179.006 85.4376H179.002ZM176.093 83.8123L178.821 84.9266L188.244 67.6843L183.581 68.7865C181.239 69.3417 179.184 70.794 177.95 72.7773L177.902 72.8537C177.169 74.0485 176.725 75.4405 176.617 76.8888L176.093 83.8082V83.8123Z"
        fill="#501464"
      />
      <path
        d="M217.843 96.2072L193.879 68.3844C193.125 67.101 191.102 67.0608 191.102 67.0608C183.726 67.3545 179.797 72.8055 177.084 79.0693C174.372 85.333 177.153 88.0325 177.153 88.0325L212.684 110.646L217.847 96.2112L217.843 96.2072Z"
        fill="#F8F4FC"
      />
      <path
        d="M212.785 110.947L177.008 88.1733C176.891 88.0606 174.158 85.3089 176.895 78.9888C181.461 68.4446 187.184 67.0165 191.09 66.8596C191.19 66.8596 193.246 66.916 194.048 68.2838L218.069 96.1669L212.781 110.951L212.785 110.947ZM177.278 87.8716L212.576 110.336L217.614 96.2474L193.726 68.513C193.016 67.3142 191.114 67.2579 191.098 67.2579C183.267 67.5717 179.595 73.7751 177.27 79.1417C174.739 84.9911 177.084 87.6583 177.282 87.8675L177.278 87.8716Z"
        fill="#501464"
      />
      <path
        d="M220.862 103.328C220.567 107.222 218.068 110.654 215.03 111.016C211.676 111.418 208.98 107.994 209.335 103.336C209.689 98.6772 212.902 95.2497 216.194 95.6479C219.177 96.006 221.156 99.4335 220.862 103.328Z"
        fill="#F8F4FC"
      />
      <path
        d="M214.51 111.249C213.321 111.249 212.18 110.782 211.261 109.901C209.73 108.437 208.936 105.979 209.137 103.32C209.339 100.64 210.52 98.1704 212.297 96.7181C213.494 95.7405 214.885 95.286 216.223 95.4509C219.246 95.817 221.374 99.2847 221.067 103.348C220.761 107.395 218.121 110.855 215.062 111.221C214.881 111.241 214.695 111.253 214.514 111.253L214.51 111.249ZM215.667 95.8169C214.59 95.8169 213.522 96.2313 212.547 97.0279C210.854 98.4158 209.73 100.777 209.536 103.352C209.343 105.89 210.093 108.228 211.539 109.612C212.527 110.553 213.728 110.971 215.01 110.819C217.887 110.473 220.37 107.178 220.664 103.316C220.954 99.4657 218.984 96.1871 216.174 95.8492C216.005 95.829 215.836 95.8169 215.671 95.8169H215.667Z"
        fill="#501464"
      />
      <path
        d="M176.294 81.4728L176.059 81.7998L209.273 105.561L209.508 105.234L176.294 81.4728Z"
        fill="#501464"
      />
      <path
        d="M212.611 100.6C210.874 102.595 211.088 105.617 213.087 107.351L271.213 157.541L277.5 150.316L219.374 100.125C217.375 98.3915 214.348 98.6047 212.611 100.6Z"
        fill="#501464"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <path
          d="M274.219 154.086L216.093 103.895C214.787 102.765 213.397 101.594 212.442 100.813C210.515 103.4 211.724 106.277 213.091 107.351L271.216 157.541L274.223 154.086H274.219Z"
          fill="#DCC8F0"
        />
      </g>
      <path
        d="M189.836 90.2247C189.698 91.5683 188.582 92.3529 187.321 92.204C186.059 92.0592 184.745 90.9891 184.31 89.577C183.766 87.7949 184.769 86.1535 186.7 86.3828C188.627 86.6121 190.009 88.531 189.832 90.2247H189.836Z"
        fill="#F8F4FC"
      />
      <path
        d="M187.623 92.4253C187.514 92.4253 187.405 92.4172 187.296 92.4052C185.894 92.2442 184.56 91.0775 184.116 89.6373C183.81 88.6356 183.963 87.6339 184.528 86.9621C185.039 86.3546 185.817 86.0771 186.724 86.1857C188.832 86.4351 190.21 88.535 190.033 90.2488C189.965 90.9206 189.654 91.508 189.162 91.9023C188.735 92.2442 188.195 92.4253 187.619 92.4253H187.623ZM186.353 86.5638C185.729 86.5638 185.197 86.7931 184.838 87.2195C184.362 87.7867 184.237 88.6476 184.503 89.5206C184.894 90.7959 186.116 91.8661 187.345 92.0069C187.933 92.0753 188.489 91.9264 188.913 91.5885C189.324 91.2586 189.574 90.7799 189.634 90.2046C189.791 88.6799 188.558 86.8052 186.676 86.5839C186.563 86.5719 186.458 86.5638 186.349 86.5638H186.353Z"
        fill="#501464"
      />
      <path
        d="M189.228 89.9392C189.082 91.3512 187.91 92.176 186.584 92.0231C185.258 91.8703 183.875 90.7478 183.42 89.2593C182.847 87.3846 183.903 85.6587 185.935 85.9001C187.962 86.1415 189.413 88.157 189.228 89.9392Z"
        fill="#F8F4FC"
      />
      <path
        d="M186.902 92.2405C186.789 92.2405 186.676 92.2325 186.563 92.2204C185.092 92.0515 183.694 90.8285 183.23 89.3159C182.908 88.2659 183.069 87.2158 183.658 86.5118C184.19 85.8721 185.008 85.5825 185.959 85.6952C188.172 85.9567 189.614 88.1612 189.429 89.9554C189.357 90.6595 189.034 91.275 188.518 91.6894C188.071 92.0474 187.507 92.2405 186.902 92.2405ZM185.568 86.0774C184.907 86.0774 184.347 86.3187 183.968 86.7693C183.464 87.3687 183.335 88.2779 183.613 89.1992C184.024 90.5428 185.31 91.6693 186.608 91.8181C187.233 91.8946 187.817 91.7336 188.264 91.3756C188.7 91.0256 188.966 90.5227 189.026 89.9153C189.191 88.3061 187.893 86.3308 185.906 86.0975C185.79 86.0854 185.677 86.0774 185.564 86.0774H185.568Z"
        fill="#501464"
      />
    </svg>
  );
}
