export default {
  'input:not(.step-counter)::-webkit-outer-spin-button, input:not(.step-counter)::-webkit-inner-spin-button':
    {
      WebkitAppearance: 'none',
      margin: 0,
    },

  'input: not(.step - counter)[type = number]': {
    MozAppearance: 'textfield',
  },
};
